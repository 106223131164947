article {
    position: relative;
    width: 300px;
    height: 200px;
    transition: all .3s ease;
}

article img:first-child {
    box-shadow: 0 60px 60px -60px rgba(0, 30, 255, 0.5);
    border-radius: 40px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

article img:last-child {
    position: absolute;
    width: 120px;
    bottom: 25%;
    left: 0;
    right: 0;
    margin: auto;
    transition: .3s ease;
    opacity: 1;
}

.category-title {
    display: none;
}

article::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-image:
        linear-gradient(to bottom,
            transparent 10%,
            rgba(0, 0, 0, 0.5) 50%,
            rgba(0, 0, 0) 95%);
    opacity: 0;
    transition: all .3s ease
}

article:hover img:last-child {
    opacity: 1;
    transform: scale(120%)
}




@media screen and (min-width: 768px) {
    article {
        position: relative;
        width: 300px;
        height: 300px;
        transition: all .3s ease;
    }

    article img:first-child {
        box-shadow: 0 60px 60px -60px rgba(0, 30, 255, 0.5);
        border-radius: 4px;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    article img:last-child {
        position: absolute;
        width: 170px;
        bottom: 40%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(25%);
        transition: .3s ease;
        opacity: 1;
    }

    .category-title {
        position: absolute;
        font-size: 2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }

    article:hover {
        transform: perspective(250px) rotateX(10deg) translateY(-5%) translateZ(0)
    }

    article:hover .category-title {
        display: block
    }

    article::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-image:
            linear-gradient(to bottom,
                transparent 10%,
                rgba(0, 0, 0, 0.5) 50%,
                rgba(0, 0, 0) 95%);
        opacity: 0;
        transition: all .3s ease
    }

    article:hover::before {
        opacity: 1
    }

    article:hover img:last-child {
        opacity: 0;
        transform: translateY(10%);
    }

}