.navbar {
    margin-top: -80px;
    z-index: 3;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0.5rem 0rem;
    background-color: black;
    color: #fff;
}

a {
    text-decoration: none;
    color: #fff;
}

.navbar-logo {
    width: 100px;
}

.navbar-menu {
    display: none
}

.navbar-menu-open {
    position: absolute;
    align-items: center;
    top: 80px;
    left: 0;
    color: black;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: auto;
    background-color: orange;
}

.navbar-menu-open a {
    padding: 20px;
    font-size: 30px
}

.mobile-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
}

.span1::before {
    content: '<GQ>';
}

.title {
    color: orange;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Days One', sans-serif;
    /* font-family: 'Secular One', sans-serif;
    font-family: 'Stick No Bills', sans-serif; */
}

.title .span1 {
    margin-left: 10px;
    transition: all .5s linear
}


@media screen and (min-width: 768px) {

    .title {
        color: orange;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 3px;
        cursor: pointer
    }

    .title .span1 {
        margin-left: 30px;
    }

    .title span {
        transition: .5s ease-in-out
    }

    .span1::before {
        content: '<';
    }

    .span2::after {
        content: '/>';
    }

    .title:hover .span1 {
        margin-right: 3px;
    }

    .title:hover .span1::after {
        content: 'GQ4';
    }

    .title:hover .span2::before {
        content: 'DEV';
    }


    .title:hover span {
        color: magenta;
        text-shadow: 0 0 10px darkmagenta,
            0 0 20px darkmagenta,
            0 0 40px darkmagenta;
    }

    .navbar-menu {
        text-align: right;
        color: orange;
        display: block;
        margin-left: auto;
        width: 60%;
        background-color: black;
        position: absolute;
        right: 10px;
        top: 25px;
    }

    .navbar-menu-open {
        display: none;
    }

    .navbar-menu a {
        cursor: pointer;
        font-size: 20px;
        margin-right: 20px;
    }

    .navbar-menu a:hover {
        color: orange
    }

    .mobile-menu {
        display: none;
    }
}