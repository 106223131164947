.categories-container {
    width: 100%;
    height: auto;
}

.categories-title {
    text-align: center;
    font-size: 2rem;
}

.categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 90%;
    margin: auto;
    padding-top: 15px;
}


@media screen and (min-width: 768px) {
    .categories {
        display: flex;
        width: 80%;
        flex-direction: row;
        justify-content: space-between;
    }
}