.footer {
    width: 100%;
    padding-top: 3rem;
    height: 380px;
}

.footer-logo {
    width: 130px;
    align-self: center;
}

.footer-title::before {
    content: "</>  GQ";
    color: orange;
    font-size: 30px;
    letter-spacing: 3px;
    font-weight: bold;
    font-family: 'Days One', sans-serif;
}

.footer-brand {
    text-align: center;
}

.footer-info {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.footer-info a {
    text-decoration: none;
    color: #fff;
    padding: 5px;
}

.footer-info a:hover {
    color: orange
}

.footer-icons {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .footer-info {
        width: 80%;
        margin: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 1.3rem;
    }



}