/* SidebarContainer */
.sidebar-container {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: 1;
  top: -100%;
}

.sidebar-container.open {
  opacity: 1;
  top: 0;
}

/* CloseIcon */
.close-icon {
  color: #fff;
}

/* Icon */
.icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

/* SidebarWrapper */
.sidebar-wrapper {
  color: #fff;
}

/* SidebarMenu */
.sidebar-menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;
}

@media screen and (max-width: 480px) {
  .sidebar-menu {
    grid-template-rows: repeat(6, 60px);
  }
}

/* SidebarLink */
.sidebar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration:none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.sidebar-link:hover {
  color: #f0ab00;
  transition: 0.2s ease-in-out;
}

/* SideBtnWrap */
.side-btn-wrap {
  display: flex;
  justify-content: center;
}

/* SidebarRoute */
.sidebar-route {
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 16px 64px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}

.sidebar-route:hover {
  background: #fff;
  transition: 0.2s ease-in-out;
  color: #010606;
}
